import { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import InvalidAccess from "./InvalidAccess";

export default function SaleApp() {
    const user_id = localStorage.getItem("user");
    const ryts = localStorage.getItem("user_rights");
    const corp_id = localStorage.getItem("business_id");
    const [filtered, setFiltered] = useState("");
    const [filteredInvoiced, setFilteredInvoiced] = useState("");
    const [filteredApp, setFilteredApp] = useState("");
    
    const [quoteList, setQuoteList] = useState([
        {
        id: 0,
        name: "",
        creator_id: user_id,
        user_id: user_id,
        },
    ]);
    const [orgMembers, setOrgMembers] = useState([{id:0, name:"Harleen Grover"}]);

    const getQuotes= {
        id: 0,
        user_id: 0,
        corp_id: corp_id,
        module: "getQuoteAcc",
        product: "quotationB",
    };
    const getOrgMem =({
        cusID: 0,
        corp_id: corp_id,
        user_id: user_id,
        module: "getOrgMem",
        product: "quotationB",
    });
    const handleFilter = (event)=>{
        const { name, value } = event.target;
        setFiltered(value);
    }
    const handleFilterInvoiced = (event)=>{
        const { name, value } = event.target;
        setFilteredInvoiced(value);
    }
    const handleFilterApp = (event)=>{
        const { name, value } = event.target;
        setFilteredApp(value);
    }
    const generatePDF = (pdfID) => {
        localStorage.setItem("generatePDF", pdfID);
        window.open("../pdf");
    };
    async function AxiosExpen(formData) {
        toast.warning("please wait!");
        JSON.stringify(formData);
        axios({
        method: "post",
        url: "https://www.bestwebdealers.com/reactManager/",
        data: formData,
        }).then((res) => {
            if(res.data != undefined){
                if( formData.module=="getOrgMem" ){
                    setOrgMembers(res.data.data);
                }
                if( formData.module === "getQuoteAcc" ){
                    if (formData.id === 0) {
                        setQuoteList(res.data.data);
                    }
                }
            }
            if(formData.module=="accAction"){
                if(res.data.search("record u")){
                    if(formData.who=="inv_acc"){
                        setQuoteList(quoteList.map(ex =>( ex.id==formData.id ? {...ex, ['fin_id']:1, ['fin_date']:"1990-1-1"} : ex )));
                    }else{
                        setQuoteList(quoteList.map(ex =>( ex.id==formData.id ? {...ex, ['ac_id']:1, ['ac_dt']:"1990-1-1"} : ex )));
                    }
                    toast.success("Sale approved.!");
                }else{
                    toast.warning("something went wrong!");
                }
            }
        });
    }
    const aprv = ((wo,id)=>{
        const aprvExp = {
            who: wo,
            corp_id: corp_id,
            user_id: user_id,
            id:id,
            ryts: ryts,
            module: "accAction",
            product: "quotationB",  
        }
        AxiosExpen(aprvExp);
    });
    useEffect(() => {
        AxiosExpen(getOrgMem);
        AxiosExpen(getQuotes);
    }, []);
    if (
    localStorage.getItem("user") == 20000 ||
    localStorage.getItem("user") == ""
  ) {
    return <InvalidAccess />;
  }
    return (
      <div>
        <Dashboard />
        <div id="content_area">
            <h2>Sales Approval</h2>
            <div id="list_section" style={{width: '32%'}}>
                
                <h2>Invoiced</h2>
                <h3><form style={{float:'none'}} ><input type="text" placeholder="filter list by Agent Name" value={filteredInvoiced} onChange={handleFilterInvoiced} /></form></h3>
                <div>
                    <ul>
                    {quoteList.filter(function(d){ var mem = orgMembers.filter(orgM=>orgM.id==d.user_id);  return (mem[0]!=undefined) ? mem[0].name.toLowerCase().indexOf(filteredInvoiced.toLowerCase())!=-1 : "" ;}).map((exs)=>{
                        if(exs.fin_id==null || exs.fin_id==0){ return ""; }
                        const mem = orgMembers.filter(orgM=>orgM.id==exs.user_id);
                        var loc = 'https://www.google.com/maps/place/'+exs.loc;
                        var clsot = "";
                        return <li key={exs.id} className={clsot} >
                        <p>
                            <small><i>{mem[0].name}</i></small><br />
                        {exs.name}
                        <small>(Q.A. NO.: qa{exs.id})</small>
                            </p><button onClick={() => generatePDF(exs.id)}>View</button>
                        </li>;
                    })}
                    </ul>
                </div>
            </div>
            <div id="list_section" style={{width: '32%'}}>
                <h2>Approved (Inv.)</h2>
                <h3><form style={{float:'none'}} ><input type="text" placeholder="filter list by Agent Name" value={filtered} onChange={handleFilter} /></form></h3>
                <div>
                    <ul>
                    {quoteList.filter(function(d){ var mem = orgMembers.filter(orgM=>orgM.id==d.user_id);  return (mem[0]!=undefined) ? mem[0].name.toLowerCase().indexOf(filtered.toLowerCase())!=-1 : "" ;}).map((exs)=>{
                        if(exs.ac_dt==null || (exs.fin_id!=null && exs.fin_id!=0)){ return ""; }
                        const mem = orgMembers.filter(orgM=>orgM.id==exs.user_id);
                        var loc = 'https://www.google.com/maps/place/'+exs.loc;
                        var clsot = "";
                        return <li key={exs.id} className={clsot} >
                        <p>
                            <small><i>{mem[0].name}</i></small><br />
                        {exs.name}
                            <small>(Q.A. NO.: qa{exs.id}</small>) - <small>{exs.i_date}</small>
                            </p>{ryts==3 ? <button onClick={() => aprv("inv_acc",exs.id)}>Mark as Invoiced</button> : "" }
                            <button onClick={() => generatePDF(exs.id)}>View</button>
                        </li>;
                    })}
                    </ul>
                </div>
            </div>
            <div id="list_section" style={{width: '32%'}}>
                <h2>Listed Invoices</h2>
                <h3><form style={{float:'none'}}><input type="text" placeholder="filter list by Agent Name" value={filteredApp} onChange={handleFilterApp} /></form></h3>
                <div>
                    <ul>
                    {quoteList.filter(function(d){ var mem = orgMembers.filter(orgM=>orgM.id==d.user_id); return (mem[0]!=undefined) ? mem[0].name.toLowerCase().indexOf(filteredApp.toLowerCase())!=-1 : "" ;}).map((exs)=>{
                        if(exs.ac_dt!=null){ return ""; }
                        const mem = orgMembers.filter(orgM=>orgM.id==exs.user_id);
                        var loc = 'https://www.google.com/maps/place/'+exs.loc;
                        var clsot = "" ;
                        return <li key={exs.id} className={clsot} >
                        <p>
                            <small><i>{mem[0].name}</i></small><br />
                        {exs.name}
                        <small>(Q.A. NO.: qa{exs.id}</small>)
                            </p>
                            {ryts==1 ? <button onClick={() => aprv("inv",exs.id)}>Approve</button> : "" }
                            <button onClick={() => generatePDF(exs.id)}>View</button>                         
                        </li>;
                    })}
                    </ul>
                </div>
            </div>
        </div>
      </div>
    )
  }