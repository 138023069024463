import { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import axios from "axios";
import React from "react";
import InvalidAccess from "./InvalidAccess";
import { toast } from "react-toastify";


export default function Expen(){
    const user_id = localStorage.getItem("user");
    const ryts = localStorage.getItem("user_rights");
    const corp_id = localStorage.getItem("business_id");
    const [exp, setExp] = useState({loc:'',corp_id:corp_id, user_id:user_id,module:"addExp", product: "quotationB"});
    const [exst, setExst] = useState([{loc:"",user_id:""}]);
    const [orgMembers, setOrgMembers] = useState([{id:0, name:"Harleen Grover"}]);
    const getExExp = {
      corp_id: corp_id,
      user_id: user_id,
      ryts: ryts,
      module: "getExp",
      product: "quotationB",  
    }
    const [loc, setLoc] = useState("");
    const [filtered, setFiltered] = useState("");
    const [cusList, setCustomerList] = useState([
    {
      id: 0,
      company: "",
      name: "",
      user_id: "",
    },
  ]);
  const getOrgMem =({
    cusID: 0,
    corp_id: corp_id,
    user_id: user_id,
    module: "getOrgMem",
    product: "quotationB",
  });
  const getCustomer = {
    cusID: 0,
    corp_id: corp_id,
    module: "getCustomer",
    product: "quotationB",
  };
  const handleField = (e)=>{
    const { name, value } = e.target;
    setExp({...exp,[name]: value,});
  }
  function handleExp(e){
    e.preventDefault();
    AxiosExpen(exp);
  }
   async function AxiosExpen(formData) {
    toast.warning("please wait!");
    JSON.stringify(formData);
    axios({
      method: "post",
      url: "https://www.bestwebdealers.com/reactManager/",
      data: formData,
    }).then((res) => {
      if(formData.module=="getOrgMem" && res.data.data!=undefined){
        setOrgMembers(res.data.data);
      }
      if(formData.module === "getExp" && res.data.data != undefined){
        setExst(res.data.data);
      }
      if(formData.module === "addExp"){
        toast.success("punched in!");
        setExst([{id: "0",corp_id: "1043",['ac_dt']: null,['user_id']:user_id,['depart']:formData['depart'],['arrive']:formData['arrive'],['kms']:formData['kms'],['loc']:formData['loc'],['dtime']:formData['dtime'],['ac_id']:'0' }, ...exst]);
      }
      if (formData.module === "getCustomer") {
        if (res.data.data != undefined) {
          if (formData.cusID === 0) {
            setCustomerList(res.data.data);
          }
        }
        else{
          toast.warning("no data found");
        }
      }
    });
  }
useEffect(() => {
    AxiosExpen(getOrgMem);
    AxiosExpen(getCustomer);
    AxiosExpen(getExExp);
    getMyLocation();
  }, []);
      function getMyLocation() {
            const location = window.navigator && window.navigator.geolocation;
            if (location) {
            location.getCurrentPosition((position) => {
                setLoc(position.coords.latitude+","+position.coords.longitude);
                setExp({...exp,['loc']: position.coords.latitude+","+position.coords.longitude,});
            }, (error) => {
                setLoc("error");
            })
            }
        }
  
  const handleFilter = (event)=>{
    const { name, value } = event.target;
    setFiltered(value);
  }
  if (
    localStorage.getItem("user") == 20000 ||
    localStorage.getItem("user") == ""
  ) {
    return <InvalidAccess />;
  }
    return (
      <div>
        <Dashboard />
        <div id="content_area">
        <div id="list_section">
          <h2>Visits Records</h2>
          <h3><form style={{float:'none'}}>
            <input type="text" onChange={handleFilter} placeholder="filter list by name"  value={filtered}  />
          </form></h3>
          <small>
          <b>White=Unapproved</b> / <b>Maroon=Approved</b>
          </small>
          <br />
          <div>
            <ul>
              {exst.filter(function(d){ var mem = orgMembers.filter(orgM=>orgM.id==d.user_id); return (mem[0]!=undefined) ? mem[0].name.toLowerCase().indexOf(filtered.toLowerCase())!=-1 : "" ;}).map((exs)=>{
                const mem = orgMembers.filter(orgM=>orgM.id==exs.user_id);
                var loc = 'https://www.google.com/maps/place/'+exs.loc;
                var clsot = (exs.ac_id==0) ? "" : "other" ;
                return <li key={exs.id} className={clsot} >
                  <p>
                    <small><i>{mem[0].name}</i></small><br />
                  {exs.depart} to {exs.arrive} ({exs.kms} kms)<br /><small>
                     <a target='_blank' href={loc}>Loc</a> on {exs.dtime}</small>
                    </p>
                </li>;
              })}
            </ul>
            
          </div>
        </div>
        <form method="post" name="customerForm" onSubmit={handleExp}>
          <h2>
            Location Tracking and Expense Management<br />  
            <small>** you must authorize location tracking in your device</small>
          </h2>
          {/* onChange={handleChange} */}
          {/* value={CurrentQuote.customer} */}
          <small>Depart Location:</small><br />
          <select required name="depart" onChange={handleField} value={exp.depart}>
            <option value=""> - - Depart Location - - </option>
            <option value={'Home'}>{'Home'}</option>
            <option value={localStorage.getItem("business")}>{localStorage.getItem("business")}</option>
            { cusList.map((cus) => {
              if(ryts==2 && cus.user_id !== user_id){ return false; }
                return  <option key={cus.id} value={cus.company}>{cus.company} ({cus.name} )</option> ;
            })}
          </select><br />
          <small>Arrival Location:</small>
          <select required name="arrive" onChange={handleField} value={exp.arrive}>
            <option value=""> - - Arrival Location - - </option>
            <option value={'Home'}>{'Home'}</option>
            <option value={localStorage.getItem("business")}>{localStorage.getItem("business")}</option>
            { cusList.map((cus) => {
              if(ryts==2 && cus.user_id !== user_id){ return false; }
                return  <option key={cus.id} value={cus.company}>{cus.company} ({cus.name} )</option> ;
            })}
          </select>
          <small>Kilometers :</small>
            <input type="number" name="kms" min={1} value={exp.kms} placeholder="KM(s)" onChange={handleField} />
            <br /><br />
          {(loc) ? <input type="submit" name="subMit" value="Save" /> : ""}
        </form>
        <a target="_blank" href={'https://www.google.com/maps/place/'+loc}>Current Location</a>
      </div></div>
    )
  }