import { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import InvalidAccess from "./InvalidAccess";
export default function LocApp() {
    const user_id = localStorage.getItem("user");
    const ryts = localStorage.getItem("user_rights");
    const corp_id = localStorage.getItem("business_id");
    const [filtered, setFiltered] = useState("");
    const [filteredPaid, setFilteredPaid] = useState("");
    const [filteredApp, setFilteredApp] = useState("");
    
    const [exp, setExp] = useState({loc:'',corp_id:corp_id, user_id:user_id,module:"addExp", product: "quotationB"});
    const [exst, setExst] = useState([{loc:"",user_id:user_id}]);
    const [orgMembers, setOrgMembers] = useState([{id:0,user_id:0, name:"Harleen Grover"}]);

    const getExExp = {
      corp_id: corp_id,
      user_id: user_id,
      ryts: ryts,
      module: "getExp",
      product: "quotationB",  
    }
    const getOrgMem =({
        cusID: 0,
        corp_id: corp_id,
        user_id: user_id,
        module: "getOrgMem",
        product: "quotationB",
    });
    const handleFilterPaid = (event)=>{
        const { name, value } = event.target;
        setFilteredPaid(value);
    }
    const handleFilter = (event)=>{
        const { name, value } = event.target;
        setFiltered(value);
    }
    const handleFilterApp = (event)=>{
        const { name, value } = event.target;
        setFilteredApp(value);
    }

    async function AxiosExpen(formData) {
        toast.warning("please wait!");
        JSON.stringify(formData);
        axios({
        method: "post",
        url: "https://www.bestwebdealers.com/reactManager/",
        data: formData,
        }).then((res) => {
            if(formData.module=="getOrgMem" && res.data.data!=undefined){
                
                setOrgMembers(res.data.data);
            }
            if(formData.module === "getExp" && res.data.data != undefined){
                setExst(res.data.data);
                toast.success("filtering listing...");
            }
            if(formData.module=="accAction"){
                if(res.data.search("record u")){
                    if(formData.who=="who_acc"){
                        setExst(exst.map(ex =>( ex.id==formData.id ? {...ex, ['fin_id']:1, ['fin_dt']:"1990-1-1"} : {...ex} )));
                    }else{
                        setExst(exst.map(ex =>( ex.id==formData.id ? {...ex, ['ac_id']:1, ['ac_dt']:"1990-1-1"} : {...ex} )));
                    }
                    toast.success("Location approved.!");
                }else{
                    toast.warning("something went wrong!");
                }
            }
        });
    }
    const aprv = ((wo,id)=>{
        const aprvExp = {
            who: wo,
            corp_id: corp_id,
            user_id: user_id,
            id:id,
            ryts: ryts,
            module: "accAction",
            product: "quotationB",  
        }
        AxiosExpen(aprvExp);
    });
    useEffect(() => {
        AxiosExpen(getOrgMem);
        AxiosExpen(getExExp);
    }, []);
    if (
    localStorage.getItem("user") == 20000 ||
    localStorage.getItem("user") == ""
  ) {
    return <InvalidAccess />;
  }
    return (
      <div>
        <Dashboard />
        <div id="content_area">
            <h2>Expense Approval</h2>
            <div id="list_section" style={{width: '32%'}}>
                <h2>Paid (Visits)</h2>
                <h3><form style={{float:'none'}} ><input type="text" placeholder="filter list by Agent Name" value={filteredPaid} onChange={handleFilterPaid} /></form></h3>
                <div>
                    <ul>
                    {exst.filter(function(d){ var mem = orgMembers.filter(orgM=>orgM.id==d.user_id); return (mem[0]!=undefined) ? mem[0].name.toLowerCase().indexOf(filteredPaid.toLowerCase())!=-1 : "" ;}).map((exs)=>{ 
                        if(exs.fin_id==null || exs.fin_id==0){ return ""; }
                        var mem = orgMembers.filter(orgM=>orgM.id==exs.user_id);
                        var loc = 'https://www.google.com/maps/place/'+exs.loc;
                        var clsot = "";
                        return <li key={exs.id} className={clsot} >
                        <p>
                            <small><i>{mem[0].name}</i></small><br />
                            {exs.depart} to {exs.arrive} <em>({exs.kms}kms)</em><br /><small>
                            <a target='_blank' href={loc}>Loc</a> on {exs.dtime}</small>
                        </p>
                        </li>;  
                    })}
                    </ul>
                </div>
            </div>
            <div id="list_section" style={{width: '32%'}}>
                <h2>Approved (Visits)</h2>
                <h3><form style={{float:'none'}} ><input type="text" placeholder="filter list by Agent Name" value={filtered} onChange={handleFilter} /></form></h3>
                <div>
                    <ul>
                    {exst.filter(function(d){ var mem = orgMembers.filter(orgM=>orgM.id==d.user_id); return (mem[0]!=undefined) ? mem[0].name.toLowerCase().indexOf(filtered.toLowerCase())!=-1 : "" ;}).map((exs)=>{ 
                        if(exs.ac_dt==null || (exs.fin_id!=null && exs.fin_id!=0)){ return ""; }
                        var mem = orgMembers.filter(orgM=>orgM.id==exs.user_id);
                        var loc = 'https://www.google.com/maps/place/'+exs.loc;
                        var clsot = "";
                        return <li key={exs.id} className={clsot} >
                        <p>
                            <small><i>{mem[0].name}</i></small><br />
                            {exs.depart} to {exs.arrive} <em>({exs.kms}kms)</em><br /><small>
                            <a target='_blank' href={loc}>Loc</a> on {exs.dtime}</small>
                        </p>
                            {ryts==3 ? <button onClick={() => aprv("who_acc",exs.id)}>Mark as paid</button> : "" }
                        </li>;  
                    })}
                    </ul>
                </div>
            </div>
            <div id="list_section" style={{width: '32%'}}>
                <h2>Listed Visits </h2>
                <h3><form style={{float:'none'}}><input type="text" placeholder="filter list by Agent Name" value={filteredApp} onChange={handleFilterApp} /></form></h3>
                <div>
                    <ul>   
                    {exst.filter(function(d){ var mem = orgMembers.filter(orgM=>orgM.id==d.user_id);  return (mem[0]!=undefined) ? mem[0].name.toLowerCase().indexOf(filteredApp.toLowerCase())!=-1 : "" ;}).map((exs)=>{ 
                        if(exs.ac_dt!=null){ return ""; }
                        var mem = orgMembers.filter(orgM=>orgM.id==exs.user_id);
                        var loc = 'https://www.google.com/maps/place/'+exs.loc;
                        var clsot = "" ;
                        return <li key={exs.id} className={clsot} >
                        <p>
                            <small><i>{mem[0].name}</i></small><br />
                            {exs.depart} to {exs.arrive} <em>({exs.kms}kms)</em><br /><small>
                            <a target='_blank' href={loc}>Loc</a> on {exs.dtime}</small>
                        </p>
                            {ryts==1 ? <button onClick={() => aprv("who",exs.id)}>Approve</button> : "" }
                        </li>;
                    })}
                    </ul>
                </div>
            </div>
        </div>
      </div>
    )
  }